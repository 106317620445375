import { library } from '@fortawesome/fontawesome-svg-core';
import {
    faUser,
    faSignOutAlt,
    faMedal,
    faStar,
    faHome,
    faEye,
    faEyeSlash,
    faExclamationCircle,
    faArrowUp,
    faChevronCircleDown,
    faRedo,
    faSignInAlt,
} from '@fortawesome/free-solid-svg-icons';

library.add(
    faUser,
    faSignOutAlt,
    faMedal,
    faStar,
    faHome,
    faEye,
    faEyeSlash,
    faExclamationCircle,
    faArrowUp,
    faChevronCircleDown,
    faRedo,
    faSignInAlt
);

import { faFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons';

library.add(faFacebook, faInstagram);

import Vue from 'vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

Vue.component('FaIcon', FontAwesomeIcon);
