<template>
    <div :class="$style.mainContentContainer">

        <div :class="$style.header">
            <div v-if="logoPath" :class="$style.logoContainer">
                <img :src="logoPath" :alt="storeName + ' logo'" :class="$style.logo" />
            </div>
        </div>
        <div :class="$style.mainContent" class="content">
            <p class="userContent">This mobile application has been deprecated, but your points are still available. Please direct any questions about the loyalty program to the merchant.</p>
        </div>
    </div>
</template>

<script>
import { logoPath, storeName, accentColor } from './lib/config';

import { NAV_GROUP_MAIN } from 'Router';

export default {
    data() {
        return {
            mainTransition: '',
            navTransition: '',
            navGroupMain: NAV_GROUP_MAIN,
            logoPath,
            storeName,
        };
    },
    computed: {
        isLoggedIn() {
            return this.$store.getters.isLoggedIn;
        },
        tierTitle() {
            const tier = this.$store.state.tier;
            return tier && tier.name ? tier.name : null;
        },
        tierBadgeStyle() {
            const tier = this.$store.state.tier;
            if (tier && tier.color) {
                return {
                    color: tier.color,
                };
            }
            return {};
        },
        userIconStyle() {
            return {
                color: accentColor,
            };
        },
    },
    watch: {
        $route(to, from) {
            if (from.meta.navOrder !== undefined && to.meta.navOrder !== undefined) {
                if (from.meta.navGroup === to.meta.navGroup) {
                    if (from.meta.navOrder < to.meta.navOrder) {
                        this.mainTransition = 'slide-prev';
                    } else {
                        this.mainTransition = 'slide-next';
                    }
                } else {
                    if (from.meta.navGroup < to.meta.navGroup) {
                        this.mainTransition = 'slide-prev';
                        this.navTransition = 'slide-prev';
                    } else {
                        this.mainTransition = 'slide-next';
                        this.navTransition = 'slide-next';
                    }
                }
            } else {
                this.mainTransition = '';
                this.navTransition = '';
            }
        },
    },
};
</script>

<style module lang="scss">
// A value of "true" should never be committed to the repository!
$debug: false;

.mainContentContainer {
    @if ($debug) {
        background-color: mistyrose;
    }
    .header {
        display: flex;
        @if ($debug) {
            background-color: lightgreen;
        }

        .navIcon {
            font-size: 2rem;
            padding-left: 1rem;
            padding-right: 1rem;
            margin-top: -2px;
            // static width so things don't jump when the icon changes.
            width: 80px;
            height: 40px;
        }
        .logoContainer {
            margin-left: auto;
            margin-right: auto;
            margin-top: 8px;
        }
        .logo {
            max-width: 500px;
            max-height: 300px;
            // 768px is iPad pixel width, iPad pro can handle fullwidth
            @media screen and (max-width: 768px) {
                max-width: 200px;
            }
        }
    }

    .mainContent {
        @if ($debug) {
            background-color: whitesmoke;
        }
        margin: 0;

        > div {
            // Prevents the top margin of the first element of the incoming
            // view from messing things up during the transition.
            overflow: auto;
        }
    }
}
</style>
