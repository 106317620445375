<template>
    <span>
        <BField
            :label="password1Label"
            :message="[...password1Errors, ...password1Warnings]"
            :type="{ 'is-danger': password1Errors.length > 0 || password1Warnings.length > 0 }"
        >
            <BInput
                type="password"
                autocomplete="new-password"
                v-model="password1Value"
                password-reveal
                :disabled="disabled"
                @blur="password1Blurred = true"
                @focus="password1Blurred = false"
                placeholder="Password"
            />
        </BField>
        <BField>
            <PasswordStrength
                v-model="password1Value"
                autocomplete="new-password"
                strength-meter-only
                :secure-length="8"
                class="control"
                :disabled="disabled"
                @feedback="_passwordFeedback"
            />
        </BField>
        <BField
            v-if="!singleField"
            :label="password2Label"
            :message="password2Errors"
            :type="{ 'is-danger': password2Errors.length > 0 }"
        >
            <BInput
                type="password"
                v-model="password2Value"
                password-reveal
                :disabled="disabled"
                placeholder="Repeat Password"
                @blur="password2Blurred = true"
                @focus="password2Blurred = false"
            />
        </BField>
    </span>
</template>

<script>
// Load the password strength meter async as it is quite large.
const PasswordStrength = () => ({
    component: import(/* webpackChunkName: "password" */ 'vue-password-strength-meter'),
});

import { FIELD_REQUIRED } from '../lib/messages';
const PASSWORD_MISMATCH = 'Passwords must be identical';
const PASSWORD_SHORT = 'The passwords must be at least 8 characters long';

export default {
    components: { PasswordStrength },
    props: {
        disabled: {
            type: Boolean,
            required: false,
            default: false,
        },
        password1: {
            type: [String],
            required: false,
            default: null,
        },
        password2: {
            type: [String],
            required: false,
            default: null,
        },
        singleField: {
            type: Boolean,
            required: false,
            default: false,
        },
        labelPrefix: {
            type: String,
            required: false,
            default: '',
        },
    },
    data() {
        return {
            feedback: {},
            fullValidation: false,
            password1Blurred: false,
            password2Blurred: false,
        };
    },
    computed: {
        password1Label() {
            if (this.labelPrefix.length > 0) {
                return this.labelPrefix + ' Password';
            }
            return 'Password';
        },
        password2Label() {
            if (this.labelPrefix.length > 0) {
                return `Repeat ${this.labelPrefix} Password`;
            }
            return 'Repeat Password';
        },
        password1Value: {
            get() {
                return this.password1;
            },
            set(value) {
                this.$emit('update:password1', value);
            },
        },
        password2Value: {
            get() {
                if (this.password2 === null) {
                    return '';
                }
                return this.password2;
            },
            set(value) {
                this.$emit('update:password2', value);
            },
        },
        password1Errors() {
            if (this.password1 === null || this.password1.length === 0) {
                if (this.password1Blurred) {
                    return [FIELD_REQUIRED];
                }
                return [];
            }
            if (this.password1.length < 7) {
                return [PASSWORD_SHORT];
            }

            return [];
        },
        password1Warnings() {
            const warnings = [];

            if (this.feedback.warning) {
                if (this.feedback.warning.length > 0) {
                    warnings.push(this.feedback.warning);
                }

                if (this.feedback.suggestions.length > 0) {
                    for (let i = 0; i < this.feedback.suggestions.length; i++) {
                        warnings.push(this.feedback.suggestions[i]);
                    }
                }
            }

            return warnings;
        },
        password2Errors() {
            if (this.singleField === true) {
                return [];
            }
            if (this.password1 !== this.password2) {
                return [PASSWORD_MISMATCH];
            }
            return [];
        },
    },
    watch: {
        password1Errors() {
            this._emitValid();
        },
        password2Errors() {
            this._emitValid();
        },
    },
    methods: {
        reset() {
            this.password1Blurred = false;
            this.password2Blurred = false;
        },
        _isValid() {
            if (this.password1Errors.length === 0 && this.password2Errors.length === 0) {
                return true;
            }
            return false;
        },
        _passwordFeedback(e) {
            this.feedback = e;
        },
        _emitValid() {
            let isValid = this._isValid();

            if (isValid) {
                if (this.password2Value.length === 0 && this.singleField !== true) {
                    isValid = false;
                }
            }
            this.$emit('isValid', isValid);
        },
    },
};
</script>
