import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

import { store } from './store/store';
import { signUp } from './lib/config';

import Rewards from './views/RewardsList';
import Points from './views/PointsDetails';

import Login from './views/LogIn';
import MembershipTier from './views/MembershipTier';
import Profile from './views/ProfileView';

import MainNav from './nav/MainNav';
import AccountNav from './nav/AccountNav';
import LoginNav from './nav/LoginNav';
import SignUp from './views/SignUp';
import { TARGET_PATH } from './store/mutationTypes';
import ForgotPassword from './views/ForgotPassword';
import ResetPassword from './views/ResetPassword';

export const NAV_GROUP_LOGIN = 0;
export const NAV_GROUP_MAIN = 1;
export const NAV_GROUP_ACCOUNT = 2;

export const router = new VueRouter({
    mode: 'hash',
    routes: [
        {
            path: '/',
            name: 'index',
            redirect: {
                name: 'rewards',
            },
        },
        {
            path: '/rewards',
            name: 'rewards',
            components: {
                default: Rewards,
                nav: MainNav,
            },
            meta: {
                navGroup: NAV_GROUP_MAIN,
                navOrder: 0,
            },
        },
        {
            path: '/points',
            name: 'points',
            components: {
                default: Points,
                nav: MainNav,
            },
            meta: {
                navGroup: NAV_GROUP_MAIN,
                navOrder: 1,
            },
        },
        {
            path: '/tier',
            name: 'tier',
            components: {
                default: MembershipTier,
                nav: AccountNav,
            },
            meta: {
                navGroup: NAV_GROUP_ACCOUNT,
                navOrder: 0,
            },
        },
        {
            path: '/profile',
            name: 'profile',
            components: {
                default: Profile,
                nav: AccountNav,
            },
            meta: {
                navGroup: NAV_GROUP_ACCOUNT,
                navOrder: 1,
            },
        },
        {
            path: '/login',
            name: 'login',
            components: {
                default: Login,
                nav: LoginNav,
            },
            meta: {
                navGroup: NAV_GROUP_LOGIN,
                navOrder: 0,
            },
        },
        {
            path: '/signup',
            name: 'signup',
            components: {
                default: SignUp,
                nav: LoginNav,
            },
            meta: {
                navGroup: NAV_GROUP_LOGIN,
                navOrder: 1,
            },
        },
        {
            path: '/forgot_password',
            name: 'forgot_password',
            components: {
                default: ForgotPassword,
                nav: LoginNav,
            },
            meta: {
                navGroup: NAV_GROUP_LOGIN,
                navOrder: -1,
            },
        },
        {
            path: '/reset_password/:token',
            components: {
                default: ResetPassword,
                nav: LoginNav,
            },
            props: {
                default: true,
                nav: false,
            },
            meta: {
                navGroup: NAV_GROUP_LOGIN,
                navOrder: -2,
            },
        },
        // Must be last, redirect all other routes to the home page.
        {
            path: '*',
            redirect: '/',
        },
    ],
});

let signUpSemaphore = true;

router.beforeEach((to, from, next) => {
    const isLoggedIn = store.getters.isLoggedIn;
    if (to.meta.navGroup === NAV_GROUP_LOGIN && isLoggedIn) {
        next({
            replace: true,
            name: 'index',
        });
        return;
    }

    if (to.meta.navGroup !== NAV_GROUP_LOGIN && !isLoggedIn) {
        store.commit(TARGET_PATH, to.fullPath);

        next({
            replace: true,
            name: 'login',
        });
        return;
    }

    if (!isLoggedIn && to.name !== 'signup' && signUp !== undefined && signUpSemaphore) {
        signUpSemaphore = false;
        next({
            replace: true,
            name: 'signup',
        });
        return;
    }

    next();
});
