<template>
    <div>
        <BMessage type="is-danger" v-if="error === 'invalid'">
            We're sorry, something went wrong. Please try again.
        </BMessage>
        <BMessage type="is-danger" v-else-if="error === 'not_found'">
            This reset link is either invalid or expired.
            <RouterLink :to="{ name: 'forgot_password' }">Please request a new password reset.</RouterLink>
        </BMessage>
        <form @submit.prevent="submitForm">
            <SetPasswordFields
                :password1.sync="password1"
                :password2.sync="password2"
                :disabled="processing"
                @isValid="isValid = $event"
            />
            <BButton
                type="is-primary"
                native-type="submit"
                :loading="processing"
                :disabled="!isValid"
                :class="$style.submit"
                >Set Password</BButton
            >
        </form>
    </div>
</template>

<script>
import { axios } from '../lib/axios';

import SetPasswordFields from './SetPasswordFields';

export default {
    components: { SetPasswordFields },
    props: {
        token: {
            required: false,
            type: String,
            default: '',
        },
    },
    data() {
        return {
            password1: null,
            password2: null,
            processing: false,
            error: '',
            feedback: {},

            isValid: false,
        };
    },
    computed: {
        isReset() {
            if (this.token.length === 0) {
                return false;
            }
            return true;
        },
    },
    methods: {
        async submitForm() {
            if (!this.isValid) {
                return;
            }

            this.processing = true;

            let response;
            try {
                response = await axios.post('/login/reset_password', {
                    token: this.token,
                    password1: this.password1,
                    password2: this.password2,
                });
            } catch (e) {
                this.processing = false;
                this.password1 = this.password2 = '';
                if (e.response.data && e.response.data.error) {
                    this.error = e.response.data.error;
                } else {
                    this.error = 'invalid';
                }
                return;
            }

            this.processing = false;
            if (response.data.success === true) {
                this.$router.push({ name: 'login' });
            } else {
                this.error = response.data.error;
            }
        },
    },
};
</script>

<style module>
.submit {
    margin-top: 1em;
}
</style>
