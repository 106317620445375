var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "FormLayout",
    {
      scopedSlots: _vm._u([
        {
          key: "header",
          fn: function() {
            return [_c("h1", [_vm._v(_vm._s(_vm.appTitle) + " Sign Up")])]
          },
          proxy: true
        }
      ])
    },
    [
      _vm._v(" "),
      _c(
        "transition",
        { attrs: { name: "slide-prev" } },
        [
          _vm.success === false
            ? _c(
                "div",
                [
                  _vm.error === "user_exists"
                    ? _c(
                        "BMessage",
                        { attrs: { type: "is-danger" } },
                        [
                          _vm._v(
                            "\n                This email address is already in use. "
                          ),
                          _c("br"),
                          _vm._v("\n                Please "),
                          _c(
                            "RouterLink",
                            { attrs: { to: { name: "login" } } },
                            [_vm._v("Log in")]
                          ),
                          _vm._v(" with your existing account.\n            ")
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.error === "unknown"
                    ? _c("BMessage", { attrs: { type: "is-danger" } }, [
                        _vm._v(
                          "\n                We're sorry, something went wrong. Please try again.\n            "
                        )
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "form",
                    {
                      attrs: { method: "post" },
                      on: {
                        submit: function($event) {
                          $event.preventDefault()
                          return _vm.submitForm.apply(null, arguments)
                        }
                      }
                    },
                    [
                      _c(
                        "BField",
                        {
                          attrs: {
                            label: "First Name",
                            message: _vm.firstNameErrors,
                            type: {
                              "is-danger": _vm.firstNameErrors.length > 0
                            }
                          }
                        },
                        [
                          _c("BInput", {
                            attrs: {
                              placeholder: "First Name",
                              disabled: _vm.processing
                            },
                            on: {
                              blur: function($event) {
                                return _vm._blur("firstName")
                              },
                              focus: function($event) {
                                return _vm._focus("firstName")
                              }
                            },
                            model: {
                              value: _vm.firstName,
                              callback: function($$v) {
                                _vm.firstName = $$v
                              },
                              expression: "firstName"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "BField",
                        {
                          attrs: {
                            label: "Last Name",
                            message: _vm.lastNameErrors,
                            type: { "is-danger": _vm.lastNameErrors.length > 0 }
                          }
                        },
                        [
                          _c("BInput", {
                            attrs: {
                              placeholder: "Last Name",
                              disabled: _vm.processing
                            },
                            on: {
                              blur: function($event) {
                                return _vm._blur("lastName")
                              },
                              focus: function($event) {
                                return _vm._focus("lastName")
                              }
                            },
                            model: {
                              value: _vm.lastName,
                              callback: function($$v) {
                                _vm.lastName = $$v
                              },
                              expression: "lastName"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "BField",
                        {
                          attrs: {
                            label: "Email",
                            message: _vm.emailErrors,
                            type: { "is-danger": _vm.emailErrors.length > 0 }
                          }
                        },
                        [
                          _c("BInput", {
                            attrs: {
                              placeholder: "Email",
                              disabled: _vm.processing
                            },
                            on: {
                              blur: function($event) {
                                return _vm._blur("email")
                              },
                              focus: function($event) {
                                return _vm._focus("email")
                              }
                            },
                            model: {
                              value: _vm.email,
                              callback: function($$v) {
                                _vm.email = $$v
                              },
                              expression: "email"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("SetPasswordFields", {
                        attrs: {
                          password1: _vm.password,
                          disabled: _vm.processing,
                          "single-field": ""
                        },
                        on: {
                          "update:password1": function($event) {
                            _vm.password = $event
                          },
                          isValid: function($event) {
                            _vm.passwordValid = $event
                          }
                        }
                      }),
                      _vm._v(" "),
                      _c(
                        "BButton",
                        {
                          attrs: {
                            type: "is-primary",
                            "native-type": "submit",
                            loading: _vm.processing,
                            disabled: !_vm.isValid
                          }
                        },
                        [
                          _vm._v(
                            "\n                    Sign Up\n                "
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.success === true
            ? _c("BMessage", { attrs: { type: "is-success" } }, [
                _vm._v(
                  "\n            " + _vm._s(_vm.successMessage) + "\n        "
                )
              ])
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }