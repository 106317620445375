<template>
    <FormLayout>
        <h1>Reset Password</h1>
        <SetPassword :token="token" />
    </FormLayout>
</template>

<script>
import FormLayout from '../layouts/FormLayout';
import SetPassword from '../components/SetPassword';

export default {
    components: { FormLayout, SetPassword },
    props: {
        token: {
            required: true,
            type: String,
        },
    },
};
</script>
