<template>
    <NavBar
        :links="[
            { route: 'tier', label: 'Membership Tier' },
            { route: 'profile', label: 'Profile' },
        ]"
    />
</template>

<script>
import NavBar from '../components/NavBar';

export default {
    components: { NavBar },
};
</script>
