var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.initialized
    ? _c("LoadingIndicator")
    : _c("div", [
        _c("div", { staticClass: "userContent", class: _vm.$style.content }, [
          _c("div", { class: _vm.$style.logOutWrapper }, [
            _c(
              "a",
              { attrs: { href: "/logout" } },
              [
                _c("FaIcon", { attrs: { icon: "sign-out-alt" } }),
                _vm._v(" Log out")
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "box" },
            [
              _c("h3", [_vm._v("Change First & Last Name")]),
              _vm._v(" "),
              _c(
                "BMessage",
                {
                  attrs: {
                    active: _vm.errorsName.length > 0,
                    type: "is-danger"
                  }
                },
                _vm._l(_vm.errorsName, function(e) {
                  return _c("div", [_vm._v(_vm._s(e))])
                }),
                0
              ),
              _vm._v(" "),
              _c(
                "BMessage",
                { attrs: { active: _vm.completeName, type: "is-success" } },
                [
                  _vm._v(
                    "\n                    Name successfully set!\n                "
                  )
                ]
              ),
              _vm._v(" "),
              _c("SetNameFields", {
                ref: "setName",
                staticStyle: { "margin-bottom": "1em", display: "block" },
                attrs: { firstName: _vm.firstName, lastName: _vm.lastName },
                on: {
                  "update:firstName": function($event) {
                    _vm.firstName = $event
                  },
                  "update:first-name": function($event) {
                    _vm.firstName = $event
                  },
                  "update:lastName": function($event) {
                    _vm.lastName = $event
                  },
                  "update:last-name": function($event) {
                    _vm.lastName = $event
                  },
                  isValid: function($event) {
                    _vm.isValid = $event
                  }
                }
              }),
              _vm._v(" "),
              _c(
                "BButton",
                {
                  attrs: {
                    loading: _vm.processingName,
                    disabled: !_vm.nameFormIsValid,
                    type: "is-primary"
                  },
                  on: { click: _vm.setName }
                },
                [
                  _c("FaIcon", { attrs: { icon: "save" } }),
                  _vm._v(" Update Name\n                ")
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "box" },
            [
              _vm.hasPassword
                ? _c("h3", [_vm._v("Change Password")])
                : [
                    _c("h3", [_vm._v("Set Password")]),
                    _vm._v(" "),
                    _c("div", { staticStyle: { "margin-bottom": "1em" } }, [
                      _vm._v(
                        "You currently use a social account to login. If you want to use a password, you can set it below."
                      )
                    ])
                  ],
              _vm._v(" "),
              _c(
                "BMessage",
                { attrs: { active: _vm.errors.length > 0, type: "is-danger" } },
                _vm._l(_vm.errors, function(e) {
                  return _c("div", [_vm._v(_vm._s(e))])
                }),
                0
              ),
              _vm._v(" "),
              _c(
                "BMessage",
                { attrs: { active: _vm.complete, type: "is-success" } },
                [
                  _vm._v(
                    "\n                    Password successfully set!\n                "
                  )
                ]
              ),
              _vm._v(" "),
              _vm.hasPassword
                ? _c(
                    "BField",
                    {
                      attrs: {
                        label: "Current Password",
                        message: _vm.currentPasswordErrors,
                        type: {
                          "is-danger": _vm.currentPasswordErrors.length > 0
                        }
                      }
                    },
                    [
                      _c("BInput", {
                        attrs: {
                          type: "password",
                          autocomplete: "current-password",
                          placeholder: "CurrentPassword",
                          "password-reveal": ""
                        },
                        on: {
                          blur: function($event) {
                            _vm.currentPasswordBlurred = true
                          },
                          focus: function($event) {
                            _vm.currentPasswordBlurred = false
                          }
                        },
                        model: {
                          value: _vm.currentPassword,
                          callback: function($$v) {
                            _vm.currentPassword = $$v
                          },
                          expression: "currentPassword"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("SetPasswordFields", {
                ref: "setPasswords",
                staticStyle: { "margin-bottom": "1em", display: "block" },
                attrs: {
                  password1: _vm.password1,
                  password2: _vm.password2,
                  "label-prefix": _vm.hasPassword ? "New" : "Set"
                },
                on: {
                  "update:password1": function($event) {
                    _vm.password1 = $event
                  },
                  "update:password2": function($event) {
                    _vm.password2 = $event
                  },
                  isValid: function($event) {
                    _vm.isValid = $event
                  }
                }
              }),
              _vm._v(" "),
              _c(
                "BButton",
                {
                  attrs: {
                    loading: _vm.processing,
                    disabled: !_vm.formIsValid,
                    type: "is-primary"
                  },
                  on: { click: _vm.setPassword }
                },
                [
                  _vm.hasPassword
                    ? _c("FaIcon", { attrs: { icon: "redo" } })
                    : _vm._e(),
                  _vm._v(
                    " " +
                      _vm._s(_vm.hasPassword ? "Change" : "Set") +
                      " Password\n                "
                  )
                ],
                1
              )
            ],
            2
          ),
          _vm._v(" "),
          _vm.showSocial
            ? _c(
                "div",
                { staticClass: "box", staticStyle: { "margin-bottom": "1em" } },
                [
                  _c("h3", [_vm._v("Social Login")]),
                  _vm._v(" "),
                  _c(
                    "FacebookButton",
                    [
                      _vm.profile.oauthProviders.includes("facebook")
                        ? [
                            _vm._v(
                              "\n                        Refresh Facebook Authorization\n                    "
                            )
                          ]
                        : [
                            _vm._v(
                              "\n                        Connect with Facebook\n                    "
                            )
                          ]
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c(
                    "InstagramButton",
                    [
                      _vm.profile.oauthProviders.includes("instagram")
                        ? [
                            _vm._v(
                              "\n                        Refresh Instagram Authorization\n                    "
                            )
                          ]
                        : [
                            _vm._v(
                              "\n                        Connect with Instagram\n                    "
                            )
                          ]
                    ],
                    2
                  )
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "box", staticStyle: { "margin-bottom": "1em" } },
            [
              _c("h3", [_vm._v("Sign Up Code")]),
              _vm._v(" "),
              _c(
                "BField",
                [
                  _c("BInput", {
                    attrs: { placeholder: "Sign Up Code" },
                    on: {
                      blur: function($event) {
                        return _vm._blur("code")
                      },
                      focus: function($event) {
                        return _vm._focus("code")
                      }
                    },
                    model: {
                      value: _vm.code,
                      callback: function($$v) {
                        _vm.code = $$v
                      },
                      expression: "code"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "BMessage",
                { attrs: { active: _vm.completeCode, type: "is-success" } },
                [
                  _vm._v(
                    "\n                    Code Submitted!\n                "
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "BButton",
                {
                  attrs: {
                    loading: _vm.processingCode,
                    disabled: !_vm.codeFormIsValid,
                    type: "is-primary"
                  },
                  on: { click: _vm.setCode }
                },
                [
                  _c("FaIcon", { attrs: { icon: "upload" } }),
                  _vm._v(" Submit Code\n                ")
                ],
                1
              )
            ],
            1
          )
        ])
      ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }