<template>
    <NavBar
        :links="[
            { route: 'login', label: 'Login' },
            { route: 'signup', label: 'Sign Up' },
        ]"
    />
</template>

<script>
import NavBar from '../components/NavBar';
export default {
    components: { NavBar },
};
</script>