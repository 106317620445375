<template>
    <div :class="$style.listItem">
        <div class="columns is-mobile is-vcentered" style="width:100%">
            <div class="column has-text-centered is-4">
                <div :class="$style.valueCell">
                    <div>{{ valueLabel }}</div>
                    <div :class="$style.value">
                        {{ value }}
                    </div>
                    <div class="help" style="color: grey">{{ valueSubtitle }}</div>
                </div>
            </div>
            <div class="column is-9">
                <div :class="$style.detailCell">
                <div :class="$style.name">{{ title }}</div>
                <div :class="[$style.description,'help']">{{ description }}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        value: {
            type: String,
            required: true,
        },
        valueLabel: {
            type: String,
            required: true,
        },
        valueSubtitle: {
            type: String,
            required: false,
        },
        title: {
            type: String,
            required: true,
        },
        description: {
            required: true,
            validator: prop => typeof prop === 'string' || prop === null,
        },
    },
};
</script>

<style module lang="scss">
.listItem {
    display: flex;
    margin-top: 0.25em;
    margin-bottom: 0.25em;
    padding: 6px 0;
}
.valueCell {
    flex-basis: 100px;
    flex-shrink: 0;
    flex-grow: 0;
    text-align: center;
    padding-right: 20px;
}
.value {
    font-size: 1.5em;
}
.detailCell {
    flex-shrink: 1;
    flex-grow: 1;
}
.name {
    font-weight: bold;
    white-space: pre-wrap;
}
.description {
    white-space: pre-wrap;
}
</style>
