var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("NavBar", {
    attrs: {
      links: [
        { route: "tier", label: "Membership Tier" },
        { route: "profile", label: "Profile" }
      ]
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }