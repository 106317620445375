<template>
    <div :class="$style.wrapper">
        <svg :style="chartStyle">
            <circle :r="size / 2" :cx="size / 2" :cy="size / 2" :class="$style.background" />
            <circle
                :r="radius"
                :cx="-(size / 2)"
                :cy="size / 2"
                :class="$style.pie"
                :style="pieStyle"
                transform="rotate(-90)"
            />
        </svg>
        <div :class="$style.content">
            <slot></slot>
        </div>
    </div>
</template>

<script>
import { accentColor } from '../lib/config';

export default {
    props: {
        size: {
            type: Number,
            required: true,
        },
        percent: {
            type: Number,
            required: true,
        },
    },
    computed: {
        radius() {
            // subtract the border width
            return (this.size - 10) / 2;
        },
        pieStyle() {
            const diameter = this.radius * 2,
                circumference = diameter * Math.PI,
                value = circumference * this.percent;
            return {
                stroke: accentColor,
                'stroke-dasharray': value + ' ' + circumference,
            };
        },
        chartStyle() {
            return {
                width: this.size + 'px',
                height: this.size + 'px',
            };
        },
    },
};
</script>

<style module lang="scss">
@import '../../../css/rewards/variables';
.wrapper {
    position: relative;

    .content {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
    }
}
.background {
    fill: $white-ter;
}
.pie {
    fill: transparent;
    stroke-width: 10px;
}
</style>
