<template>
<LoadingIndicator v-if="!initialized"/>
    <div v-else>
        <div class="userContent" :class="$style.content">
            <div :class="$style.logOutWrapper">
                <a href="/logout"><FaIcon icon="sign-out-alt"/> Log out</a>
            </div>
            <div class="box">
                <h3>Change First & Last Name</h3>
                <BMessage :active="errorsName.length > 0" type="is-danger">
                    <div v-for="e in errorsName">{{ e }}</div>
                </BMessage>
                <BMessage :active="completeName" type="is-success">
                    Name successfully set!
                </BMessage>
                <SetNameFields
                        :firstName.sync="firstName"
                        :lastName.sync="lastName"
                        @isValid="isValid = $event"
                        style="margin-bottom: 1em; display: block;"
                        ref="setName"
                />
                <BButton :loading="processingName" :disabled="!nameFormIsValid" type="is-primary" @click="setName">
                    <FaIcon icon="save"/> Update Name
                </BButton>
            </div>
            <div class="box">
                <h3 v-if="hasPassword">Change Password</h3>
                <template v-else>
                    <h3>Set Password</h3>
                    <div style="margin-bottom: 1em;">You currently use a social account to login. If you want to use a password, you can set it below.</div>
                </template>
                <BMessage :active="errors.length > 0" type="is-danger">
                    <div v-for="e in errors">{{ e }}</div>
                </BMessage>
                <BMessage :active="complete" type="is-success">
                    Password successfully set!
                </BMessage>
                <BField
                        v-if="hasPassword"
                        label="Current Password"
                        :message="currentPasswordErrors"
                        :type="{ 'is-danger': currentPasswordErrors.length > 0 }"
                    >
                        <BInput
                            type="password"
                            autocomplete="current-password"
                            v-model="currentPassword"
                            placeholder="CurrentPassword"
                            password-reveal
                            @blur="currentPasswordBlurred = true"
                            @focus="currentPasswordBlurred = false"
                        />
                    </BField>

                    <SetPasswordFields
                        :password1.sync="password1"
                        :password2.sync="password2"
                        :label-prefix="hasPassword ? 'New' : 'Set'"
                        @isValid="isValid = $event"
                        style="margin-bottom: 1em; display: block;"
                        ref="setPasswords"
                />

                <BButton :loading="processing" :disabled="!formIsValid" type="is-primary" @click="setPassword">
                    <FaIcon v-if="hasPassword" icon="redo"/> {{ hasPassword ? 'Change' : 'Set' }} Password
                </BButton>
            </div>
            <div v-if="showSocial" class="box" style="margin-bottom: 1em;">
                <h3>Social Login</h3>
                <FacebookButton>
                    <template v-if="profile.oauthProviders.includes('facebook')">
                        Refresh Facebook Authorization
                    </template>
                    <template v-else>
                        Connect with Facebook
                    </template>
                </FacebookButton>
                <InstagramButton>
                    <template v-if="profile.oauthProviders.includes('instagram')">
                        Refresh Instagram Authorization
                    </template>
                    <template v-else>
                        Connect with Instagram
                    </template>
                </InstagramButton>
            </div>
            <div class="box" style="margin-bottom: 1em;">
                <h3>Sign Up Code</h3>
                <BField>
                    <BInput
                            v-model="code"
                            placeholder="Sign Up Code"
                            @blur="_blur('code')"
                            @focus="_focus('code')"
                    />
                </BField>

                <BMessage :active="completeCode" type="is-success">
                    Code Submitted!
                </BMessage>
                <BButton :loading="processingCode" :disabled="!codeFormIsValid" type="is-primary" @click="setCode">
                    <FaIcon icon="upload"/> Submit Code
                </BButton>
            </div>

        </div>
    </div>
</template>

<script>
import FacebookButton from '../components/FacebookButton';
import InstagramButton from '../components/InstagramButton';
import LoadingIndicator from '../components/LoadingIndicator';
import SetPasswordFields from '../components/SetPasswordFields';
import SetNameFields from '../components/SetNameFields';

import { mapState } from 'vuex';
import { PROFILE } from '../store/mutationTypes';
import { axios } from '../lib/axios';

import { FIELD_REQUIRED } from '../lib/messages';


export default {
    components: {InstagramButton, FacebookButton, LoadingIndicator, SetPasswordFields, SetNameFields},
    data() {
        return {
            currentPassword: '',
            currentPasswordBlurred: false,
            errors: [],
            errorsName: [],
            errorsCode: [],
            password1: '',
            password2: '',
            firstName: '',
            lastName: '',
            code: '',
            isValid: false,
            processing: false,
            complete: false,
            completeName: false,
            processingName: false,
            completeCode: false,
            processingCode: false,
            showSocial: false
        };
    },
    mounted() {
        if (this.profile.firstName) {
            this.firstName = this.profile.firstName;
        }
        if (this.profile.lastName) {
            this.lastName = this.profile.lastName;
        }
    },
    computed: {
        ...mapState(['initialized', 'profile']),
        currentPasswordErrors() {
            if (this.currentPassword === null || this.currentPassword.length === 0) {
                if (this.currentPasswordBlurred && this.hasPassword) {
                    return [FIELD_REQUIRED];
                }
            }

            return [];
        },
        nameErrors() {
            if (this.firstName.length === 0) {
                return [FIELD_REQUIRED];
            }
            if (this.lastName.length === 0) {
                return [FIELD_REQUIRED];
            }
            return [];
        },
        codeErrors() {
            if (this.code.length === 0) {
                return [FIELD_REQUIRED];
            }
            if (this.code.length === 0) {
                return [FIELD_REQUIRED];
            }
            return [];
        },
        formIsValid() {
            return this.currentPasswordErrors.length === 0;
        },
        nameFormIsValid() {
            return this.nameErrors.length === 0;
        },
        codeFormIsValid() {
            return this.codeErrors.length === 0;
        },
        hasPassword() {
            if (!this.initialized) {
                return true;
            }
            return this.profile.hasPassword;
        },
    },
    methods: {
        async setPassword() {
            if (!this.formIsValid) {
                return;
            }

            this.processing = true;
            this.complete = false;
            this.errors = [];

            let data = {};

            try {
                const response = await axios.post('/set_password', {
                    current: this.currentPassword,
                    password1: this.password1,
                    password2: this.password2,
                });

                data = response.data;
            } catch (e) {
                this.processing = false;
                this.complete = false;

                this.errors = e.response.data.errors;
                return;
            }

            // We really should create a mutation to just update the hasPassword property,
            // but I'm too lazy...
            let newProfile = this.profile;
            newProfile.hasPassword = true;
            this.$store.commit(PROFILE, newProfile);

            this.currentPassword = '';
            this.currentPasswordBlurred = false;
            this.password1 = '';
            this.password2 = '';
            this.$refs.setPasswords.reset();
            this.complete = true;
            this.processing = false;
        },
        async setName() {
            if (!this.nameFormIsValid) {
                return;
            }

            this.processingName = true;
            this.completeName = false;
            this.errorsName = [];

            let data = {};

            try {
                const response = await axios.post('/update_profile', {
                    firstName: this.firstName,
                    lastName: this.lastName,
                });

                data = response.data;
            } catch (e) {
                this.processingName = false;
                this.completeName = false;

                this.errorsName = e.response.data.errors;
                return;
            }

            let profileUpdate = this.profile;
            profileUpdate.firstName = this.firstName;
            profileUpdate.lastName = this.lastName;
            this.$store.commit(PROFILE, profileUpdate);

            this.$refs.setName.reset();
            this.completeName = true;
            this.processingName = false;
        },
        async setCode() {
            if (!this.codeFormIsValid) {
                return;
            }

            this.processingCode = true;
            this.completeCode = false;
            this.errorsCode = [];

            let data = {};

            try {
                const response = await axios.post('/add_code', {
                    code: this.code,
                });

                data = response.data;
            } catch (e) {
                this.processingCode = false;
                this.completeCode = false;

                this.errorsCode = e.response.data.errors;
                return;
            }

            let profileUpdate = this.profile;
            profileUpdate.code = this.code;
            this.$store.commit(PROFILE, profileUpdate);

            this.$refs.setName.reset();
            this.completeCode = true;
            this.processingCode = false;
        }
    },
};
</script>

<style module lang="scss">
.logOutWrapper {
    margin: 1em;
    text-align: right;
}
.content {
    margin-top: 0.5em;
}
</style>
