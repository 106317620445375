<template>
    <LoadingIndicator v-if="!initialized" />
    <div v-else>
        <ListHeader value="Redeemable Rewards" />
        <div class="userContent" :class="$style.noneAvailable" v-if="redeemable.length === 0">
            We're sorry, there aren't currently any rewards that you can redeem.
        </div>
        <template v-else>
            <div v-for="r in redeemable" :key="r.id" class="listRow">
                <ListItem
                    :value="r.point_value | number"
                    value-label="Points"
                    :title="r.name"
                    :description="r.description"
                    class="userContent"
                />
            </div>
        </template>

        <template v-if="allRewards.length > 0">
            <ListHeader value="All Available Rewards" />

            <div v-for="r in allRewards" :key="r.id" class="listRow">
                <ListItem
                    :value="r.point_value | number"
                    value-label="Points"
                    :title="r.name"
                    :description="r.description"
                    class="userContent"
                />
            </div>
        </template>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import _sortBy from 'lodash/sortBy';

import LoadingIndicator from '../components/LoadingIndicator';
import ListHeader from '../components/ListHeader';
import ListItem from '../components/ListItem';

function sortRewards(list) {
    return _sortBy(list, ['point_value', 'name']);
}

export default {
    components: { ListItem, ListHeader, LoadingIndicator },
    computed: {
        ...mapState(['rewards', 'points', 'initialized']),
        redeemable() {
            let r = [];

            for (let i = 0; i < this.rewards.length; i++) {
                const reward = this.rewards[i];

                if (reward.point_value <= this.points) {
                    r.push(reward);
                }
            }

            return sortRewards(r);
        },
        allRewards() {
            let r = [];

            for (let i = 0; i < this.rewards.length; i++) {
                const reward = this.rewards[i];

                if (reward.point_value > this.points) {
                    r.push(reward);
                }
            }

            return sortRewards(r);
        },
    },
};
</script>

<style module>
.noneAvailable {
    margin-top: 0.5em;
    margin-bottom: 0.5em;
}
</style>
