var render = function(_h, _vm) {
  var _c = _vm._c
  return _c(
    "div",
    { class: _vm.$style.loading },
    [
      _c("BLoading", {
        attrs: { "is-full-page": false, active: true, "can-cancel": false }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }