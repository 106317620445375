<template>
    <div>
        <nav class="tabs is-fullwidth">
            <ul>
                <li v-for="l in links" :class="{ 'is-active': $route.name === l.route }">
                    <RouterLink :to="{ name: l.route }" :style="linkStyle(l.route)">{{ l.label }}</RouterLink>
                </li>
            </ul>
        </nav>
    </div>
</template>

<script>
import { accentColor } from '../lib/config';

export default {
    props: {
        links: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            transitionName: 'slide-next',
        };
    },
    watch: {
        $route(to, from) {
            const fromId = this.links.findIndex(e => e.route === from.name),
                toId = this.links.findIndex(e => e.route === to.name);
            let transitionName;

            if (fromId >= 0 && toId >= 0) {
                if (fromId > toId) {
                    transitionName = 'slide-next';
                } else {
                    transitionName = 'slide-prev';
                }
            }
            this.$emit('input', transitionName);
        },
    },
    methods: {
        linkStyle(route) {
            if (route === this.$route.name) {
                return {
                    'border-bottom-color': accentColor,
                };
            }
            return {};
        },
    },
};
</script>
