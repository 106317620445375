var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "FormLayout",
    [
      _c("h1", [_vm._v("Forgot Password")]),
      _vm._v(" "),
      _c("transition", { attrs: { name: "slide-next" } }, [
        _vm.complete
          ? _c(
              "div",
              [
                _c("BMessage", { attrs: { type: "is-success" } }, [
                  _vm._v(
                    "\n                Please check your email for a message with password reset instructions.\n            "
                  )
                ]),
                _vm._v(" "),
                _c("RouterLink", { attrs: { to: { name: "login" } } }, [
                  _vm._v("Back to Login")
                ])
              ],
              1
            )
          : _c(
              "form",
              {
                attrs: { method: "post" },
                on: {
                  submit: function($event) {
                    $event.preventDefault()
                    return _vm.submitForm.apply(null, arguments)
                  }
                }
              },
              [
                _c(
                  "BField",
                  {
                    attrs: {
                      label: "Username or Email",
                      message: {
                        "Please supply a username or email address": _vm.error
                      },
                      type: { "is-danger": _vm.error }
                    }
                  },
                  [
                    _c("BInput", {
                      attrs: {
                        placeholder: "username",
                        name: "username",
                        disabled: _vm.processing
                      },
                      model: {
                        value: _vm.username,
                        callback: function($$v) {
                          _vm.username = $$v
                        },
                        expression: "username"
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "BButton",
                  {
                    attrs: {
                      type: "is-primary",
                      "native-type": "submit",
                      loading: _vm.processing
                    }
                  },
                  [_vm._v("Send Reset Email")]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { class: _vm.$style.backToLogin },
                  [
                    _c("RouterLink", { attrs: { to: { name: "login" } } }, [
                      _vm._v("Back to login")
                    ])
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "BMessage",
                  { class: _vm.$style.backToLogin, attrs: { type: "is-info" } },
                  [
                    _vm._v(
                      "\n                You can only request a new password once every 2 hours.\n            "
                    )
                  ]
                )
              ],
              1
            )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }