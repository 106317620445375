<template>
<span>
    <BField
            label="First Name"
            :message="[...firstNameErrors]"
            :type="{'is-danger': firstNameErrors.length > 0}"
    >
        <BInput
                type="text"
                v-model="firstNameValue"
                :disabled="disabled"
                @blur="firstNameBlurred = true"
                @focus="firstNameBlurred = false"
                autocomplete="given-name"
                placeholder="First Name"
        />
    </BField>
    <BField
            label="Last Name"
            :message="lastNameErrors"
            :type="{'is-danger': lastNameErrors.length > 0}"
    >
        <BInput
                type="text"
                v-model="lastNameValue"
                :disabled="disabled"
                autocomplete="family-name"
                placeholder="Last Name"
                @blur="lastNameBlurred = true"
                @focus="lastNameBlurred = false"
        />
    </BField>
</span>
</template>

<script>
import {FIELD_REQUIRED} from "../lib/messages";

export default {
    props: {
        disabled: {
            type: Boolean,
            required: false,
            default: false,
        },
        firstName: {
            type: [String],
            required: false,
            default: null,
        },
        lastName: {
            type: [String],
            required: false,
            default: null,
        },
    },
    data() {
        return {
            fullValidation: false,
            firstNameBlurred: false,
            lastNameBlurred: false,
        };
    },
    computed: {
        firstNameValue: {
            get() {return this.firstName},
            set(value) {
                this.$emit('update:firstName', value);
            }
        },
        lastNameValue: {
            get() {
                if (this.lastName === null) {
                    return '';
                }
                return this.lastName
            },
            set(value) {
                this.$emit('update:lastName', value);
            }
        },
        firstNameErrors() {
            if (this.firstName === null || this.firstName.length === 0) {
                if (this.firstNameBlurred) {
                    return [FIELD_REQUIRED];
                }
                return [];
            }
            return [];
        },
        lastNameErrors() {
            if (this.lastName === null || this.lastName.length === 0) {
                if (this.lastNameBlurred) {
                    return [FIELD_REQUIRED];
                }
                return [];
            }
            return [];
        },
    },
    watch: {
        firstNameErrors() {
            this._emitValid();
        },
        lastNameErrors() {
            this._emitValid();
        },
    },
    methods: {
        reset() {
            this.firstNameBlurred = false;
            this.lastNameBlurred = false;
        },
        _isValid() {
            if (this.firstNameErrors.length === 0 && this.lastNameErrors.length === 0) {
                return true;
            }
            return false;
        },
        _emitValid() {
            let isValid = this._isValid();
            this.$emit('isValid', isValid);
        },
    },
}
</script>
