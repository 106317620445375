import '../../css/rewards/main.scss';

import 'core-js/stable';

import Vue from 'vue';
import { router } from 'Router';
import { store } from './store/store';

import 'icons';

import Buefy from 'buefy';
Vue.use(Buefy, {
    defaultIconPack: 'mdi',
    defaultIconComponent: 'FaIcon',
});

Vue.filter('number', function(value) {
    // Add the thousands separator.
    return value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
});

import App from 'App';

new Vue({
    router,
    store,
    el: '#app',
    render: createElement => createElement(App),
});
