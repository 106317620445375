var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: _vm.$style.listItem }, [
    _c(
      "div",
      {
        staticClass: "columns is-mobile is-vcentered",
        staticStyle: { width: "100%" }
      },
      [
        _c("div", { staticClass: "column has-text-centered is-4" }, [
          _c("div", { class: _vm.$style.valueCell }, [
            _c("div", [_vm._v(_vm._s(_vm.valueLabel))]),
            _vm._v(" "),
            _c("div", { class: _vm.$style.value }, [
              _vm._v(
                "\n                    " +
                  _vm._s(_vm.value) +
                  "\n                "
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "help", staticStyle: { color: "grey" } }, [
              _vm._v(_vm._s(_vm.valueSubtitle))
            ])
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "column is-9" }, [
          _c("div", { class: _vm.$style.detailCell }, [
            _c("div", { class: _vm.$style.name }, [_vm._v(_vm._s(_vm.title))]),
            _vm._v(" "),
            _c("div", { class: [_vm.$style.description, "help"] }, [
              _vm._v(_vm._s(_vm.description))
            ])
          ])
        ])
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }