var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: _vm.$style.wrapper }, [
    _c("svg", { style: _vm.chartStyle }, [
      _c("circle", {
        class: _vm.$style.background,
        attrs: { r: _vm.size / 2, cx: _vm.size / 2, cy: _vm.size / 2 }
      }),
      _vm._v(" "),
      _c("circle", {
        class: _vm.$style.pie,
        style: _vm.pieStyle,
        attrs: {
          r: _vm.radius,
          cx: -(_vm.size / 2),
          cy: _vm.size / 2,
          transform: "rotate(-90)"
        }
      })
    ]),
    _vm._v(" "),
    _c("div", { class: _vm.$style.content }, [_vm._t("default")], 2)
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }