var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.initialized
    ? _c("LoadingIndicator")
    : _c(
        "div",
        [
          _c("ListHeader", { attrs: { value: "Redeemable Rewards" } }),
          _vm._v(" "),
          _vm.redeemable.length === 0
            ? _c(
                "div",
                { staticClass: "userContent", class: _vm.$style.noneAvailable },
                [
                  _vm._v(
                    "\n        We're sorry, there aren't currently any rewards that you can redeem.\n    "
                  )
                ]
              )
            : _vm._l(_vm.redeemable, function(r) {
                return _c(
                  "div",
                  { key: r.id, staticClass: "listRow" },
                  [
                    _c("ListItem", {
                      staticClass: "userContent",
                      attrs: {
                        value: _vm._f("number")(r.point_value),
                        "value-label": "Points",
                        title: r.name,
                        description: r.description
                      }
                    })
                  ],
                  1
                )
              }),
          _vm._v(" "),
          _vm.allRewards.length > 0
            ? [
                _c("ListHeader", { attrs: { value: "All Available Rewards" } }),
                _vm._v(" "),
                _vm._l(_vm.allRewards, function(r) {
                  return _c(
                    "div",
                    { key: r.id, staticClass: "listRow" },
                    [
                      _c("ListItem", {
                        staticClass: "userContent",
                        attrs: {
                          value: _vm._f("number")(r.point_value),
                          "value-label": "Points",
                          title: r.name,
                          description: r.description
                        }
                      })
                    ],
                    1
                  )
                })
              ]
            : _vm._e()
        ],
        2
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }