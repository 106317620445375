var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.initialized
    ? _c("LoadingIndicator")
    : _c("div", [
        _c("div", { staticClass: "userContent", class: _vm.$style.header }, [
          _c("div", [
            _c("div", { class: _vm.$style.label }, [_vm._v("Current Tier")]),
            _vm._v(" "),
            _c(
              "div",
              { class: _vm.$style.value, style: { color: _vm.tier.color } },
              [_vm._v(_vm._s(_vm.tier.name))]
            )
          ]),
          _vm._v(" "),
          _c("div", [
            _c("div", { class: _vm.$style.label }, [_vm._v("Your Balance")]),
            _vm._v(" "),
            _c("div", { class: _vm.$style.value }, [
              _vm._v(_vm._s(_vm._f("number")(_vm.points)))
            ])
          ])
        ]),
        _vm._v(" "),
        _c(
          "div",
          { class: [_vm.$style.charts, _vm.$style["charts-" + _vm.qtyCharts]] },
          [
            _vm.nextRewardEnabled
              ? _c(
                  "div",
                  { class: _vm.$style.chartContainer },
                  [
                    _c(
                      "PieChart",
                      {
                        attrs: {
                          size: 180,
                          percent: this.points / this.nextRewardPoints
                        }
                      },
                      [
                        _c(
                          "div",
                          { class: _vm.$style.pointsTo },
                          [
                            _c(
                              "BTooltip",
                              {
                                attrs: {
                                  label: _vm.nextReward.name,
                                  position: "is-top",
                                  animated: ""
                                }
                              },
                              [
                                _vm._v(
                                  "\n                        " +
                                    _vm._s(
                                      _vm._f("number")(
                                        this.nextRewardPoints - this.points
                                      )
                                    ) +
                                    "\n                    "
                                )
                              ]
                            )
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "BTooltip",
                          {
                            attrs: {
                              label: _vm.nextReward.name,
                              position: "is-bottom",
                              animated: ""
                            }
                          },
                          [
                            _c("div", { class: _vm.$style.pointsToLabel }, [
                              _vm._v("Points to next reward")
                            ])
                          ]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.nextTierEnabled
              ? _c(
                  "div",
                  { class: _vm.$style.chartContainer },
                  [
                    _c(
                      "PieChart",
                      {
                        attrs: {
                          size: 180,
                          percent: this.pointProgress / this.nextTierPoints
                        }
                      },
                      [
                        _c(
                          "div",
                          { class: _vm.$style.pointsTo },
                          [
                            _c(
                              "BTooltip",
                              {
                                attrs: {
                                  label: _vm.nextTier.name,
                                  position: "is-top",
                                  animated: ""
                                }
                              },
                              [
                                _vm._v(
                                  "\n                        " +
                                    _vm._s(
                                      _vm._f("number")(
                                        this.nextTierPoints - this.pointProgress
                                      )
                                    ) +
                                    "\n                    "
                                )
                              ]
                            )
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "BTooltip",
                          {
                            attrs: {
                              label: _vm.nextTier.name,
                              position: "is-bottom",
                              animated: ""
                            }
                          },
                          [
                            _c("div", { class: _vm.$style.pointsToLabel }, [
                              _vm._v("Points to next tier")
                            ])
                          ]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.topTierEnabled
              ? _c(
                  "div",
                  { class: _vm.$style.chartContainer },
                  [
                    _c(
                      "PieChart",
                      {
                        attrs: {
                          size: 180,
                          percent: this.pointProgress / this.topTierPoints
                        }
                      },
                      [
                        _c(
                          "div",
                          { class: _vm.$style.pointsTo },
                          [
                            _c(
                              "BTooltip",
                              {
                                attrs: {
                                  label: _vm.topTier.name,
                                  position: "is-top",
                                  animated: ""
                                }
                              },
                              [
                                _vm._v(
                                  "\n                        " +
                                    _vm._s(
                                      _vm._f("number")(
                                        this.topTierPoints - this.pointProgress
                                      )
                                    ) +
                                    "\n                    "
                                )
                              ]
                            )
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "BTooltip",
                          {
                            attrs: {
                              label: _vm.topTier.name,
                              position: "is-bottom",
                              animated: ""
                            }
                          },
                          [
                            _c("div", { class: _vm.$style.pointsToLabel }, [
                              _vm._v("Points to top tier")
                            ])
                          ]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              : _vm._e()
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "userContent", class: _vm.$style.table },
          [
            _c(
              "BTable",
              {
                attrs: { data: _vm.transactions },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(props) {
                      return [
                        _c("BTableColumn", { attrs: { label: "Date" } }, [
                          _vm._v(
                            "\n                    " +
                              _vm._s(_vm._f("date")(props.row.date)) +
                              "\n                "
                          )
                        ]),
                        _vm._v(" "),
                        _c(
                          "BTableColumn",
                          { attrs: { label: "Description" } },
                          [
                            _vm._v(
                              "\n                    " +
                                _vm._s(props.row.description) +
                                "\n                "
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "BTableColumn",
                          { attrs: { label: "Points", numeric: "" } },
                          [
                            _vm._v(
                              "\n                    " +
                                _vm._s(_vm._f("number")(props.row.points)) +
                                "\n                "
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "BTableColumn",
                          { attrs: { label: "Total", numeric: "" } },
                          [
                            _vm._v(
                              " $" +
                                _vm._s(
                                  _vm._f("number")(props.row.purchaseTotal)
                                ) +
                                " "
                            )
                          ]
                        )
                      ]
                    }
                  }
                ])
              },
              [
                _vm._v(" "),
                _vm.transactionsLoading
                  ? _c(
                      "template",
                      { slot: "footer" },
                      [_c("LoadingIndicator")],
                      1
                    )
                  : !_vm.transactionsEnd
                  ? _c("template", { slot: "footer" }, [
                      _c(
                        "div",
                        { staticClass: "has-text-centered" },
                        [
                          _c(
                            "BButton",
                            {
                              attrs: { type: "is-text" },
                              on: {
                                click: function($event) {
                                  return _vm.fetchTransactions(true)
                                }
                              }
                            },
                            [
                              _c("FaIcon", {
                                attrs: { icon: "chevron-circle-down" }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ])
                  : _vm._e()
              ],
              2
            )
          ],
          1
        )
      ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }