var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.initialized
    ? _c("LoadingIndicator")
    : _c(
        "div",
        _vm._l(_vm.tiers, function(tier, k) {
          return _c("div", { key: k, class: _vm.$style.row }, [
            _c(
              "div",
              {
                staticClass: "userContent",
                class: [
                  _vm.$style.tier,
                  _vm.inactiveTiers.indexOf(tier.id) >= 0
                    ? _vm.$style.tierInactive
                    : ""
                ],
                style: _vm.hidden(tier)
              },
              [
                _c(
                  "div",
                  {
                    class: _vm.$style.iconColumn,
                    style: { color: tier.color }
                  },
                  [
                    _c("FaIcon", {
                      class: _vm.$style.icon,
                      attrs: { icon: "star" }
                    }),
                    _vm._v(" "),
                    k > 0
                      ? _c("div", {
                          class: [_vm.$style.vLine, _vm.$style.vLineFirst],
                          style: { "background-color": _vm.colors[k - 1] }
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _c("div", {
                      class: [_vm.$style.vLine, _vm.$style.vLineLast],
                      style: { "background-color": tier.color }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { class: _vm.$style.detailsColumn }, [
                  _c("div", { class: _vm.$style.tierTitle }, [
                    _vm._v(_vm._s(tier.name))
                  ]),
                  _vm._v(" "),
                  _c("div", { class: _vm.$style.tierDesc }, [
                    _vm._v(_vm._s(tier.description))
                  ])
                ])
              ]
            )
          ])
        }),
        0
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }