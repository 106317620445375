<template>
    <LoadingIndicator v-if="!initialized" />
    <div v-else>
        <div class="userContent" :class="$style.header">
            <div>
                <div :class="$style.label">Current Tier</div>
                <div :class="$style.value" :style="{ color: tier.color }">{{ tier.name }}</div>
            </div>
            <div>
                <div :class="$style.label">Your Balance</div>
                <div :class="$style.value">{{ points | number }}</div>
            </div>
        </div>
        <div :class="[$style.charts, $style['charts-' + qtyCharts]]">
            <div v-if="nextRewardEnabled" :class="$style.chartContainer">
                <PieChart :size="180" :percent="this.points / this.nextRewardPoints">
                    <div :class="$style.pointsTo">
                        <BTooltip :label="nextReward.name" position="is-top" animated>
                            {{ (this.nextRewardPoints - this.points) | number }}
                        </BTooltip>
                    </div>
                    <BTooltip :label="nextReward.name" position="is-bottom" animated>
                        <div :class="$style.pointsToLabel">Points to next reward</div>
                    </BTooltip>
                </PieChart>
            </div>
            <div v-if="nextTierEnabled" :class="$style.chartContainer">
                <PieChart :size="180" :percent="this.pointProgress / this.nextTierPoints">
                    <div :class="$style.pointsTo">
                        <BTooltip :label="nextTier.name" position="is-top" animated>
                            {{ (this.nextTierPoints - this.pointProgress) | number }}
                        </BTooltip>
                    </div>

                    <BTooltip :label="nextTier.name" position="is-bottom" animated>
                        <div :class="$style.pointsToLabel">Points to next tier</div>
                    </BTooltip>
                </PieChart>
            </div>
            <div v-if="topTierEnabled" :class="$style.chartContainer">
                <PieChart :size="180" :percent="this.pointProgress / this.topTierPoints">
                    <div :class="$style.pointsTo">
                        <BTooltip :label="topTier.name" position="is-top" animated>
                            {{ (this.topTierPoints - this.pointProgress) | number }}
                        </BTooltip>
                    </div>
                    <BTooltip :label="topTier.name" position="is-bottom" animated>
                        <div :class="$style.pointsToLabel">Points to top tier</div>
                    </BTooltip>
                </PieChart>
            </div>
        </div>
        <div class="userContent" :class="$style.table">
            <BTable :data="transactions">
                <template slot-scope="props">
                    <BTableColumn label="Date">
                        {{ props.row.date | date }}
                    </BTableColumn>
                    <BTableColumn label="Description">
                        {{ props.row.description }}
                    </BTableColumn>
                    <BTableColumn label="Points" numeric>
                        {{ props.row.points | number }}
                    </BTableColumn>
                    <BTableColumn label="Total" numeric> ${{ props.row.purchaseTotal | number }} </BTableColumn>
                </template>
                <template slot="footer" v-if="transactionsLoading">
                    <LoadingIndicator />
                </template>
                <template slot="footer" v-else-if="!transactionsEnd">
                    <div class="has-text-centered">
                        <BButton @click="fetchTransactions(true)" type="is-text">
                            <FaIcon icon="chevron-circle-down" />
                        </BButton>
                    </div>
                </template>
            </BTable>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';

import LoadingIndicator from '../components/LoadingIndicator';
import PieChart from '../components/PieChart';

export default {
    components: { LoadingIndicator, PieChart },
    created() {
        if (this.transactions.length === 0) {
            // Only load if there aren't already some there.
            this.fetchTransactions();
        }
    },
    computed: {
        ...mapState([
            'points',
            'pointProgress',
            'tier',
            'tiers',
            'rewards',
            'transactions',
            'transactionsLoading',
            'transactionsEnd',
            'initialized',
        ]),
        nextReward() {
            let reward = null;
            for (let i = 0; i < this.rewards.length; i++) {
                if (this.rewards[i].point_value > this.points) {
                    reward = this.rewards[i];
                    break;
                }
            }
            return reward;
        },
        nextRewardPoints() {
            return this.nextReward.point_value;
        },
        nextRewardEnabled() {
            return this.nextReward !== null;
        },

        nextTier() {
            let nextTier = null;
            if (!this.tier) {
                return nextTier;
            }
            for (let i = 0; i < this.tiers.length; i++) {
                if (this.tiers[i].id === this.tier.id) {
                    if (this.tiers[i - 1] !== undefined) {
                        nextTier = this.tiers[i - 1];
                    }
                    break;
                }
            }

            return nextTier;
        },
        nextTierPoints() {
            return this.nextTier.threshold;
        },
        nextTierEnabled() {
            if (this.nextTier === null) {
                return false;
            }

            if (this.nextTierPoints < this.pointProgress) {
                return false;
            }

            return true;
        },

        topTier() {
            return this.tiers[0];
        },
        topTierPoints() {
            return this.topTier.threshold;
        },
        topTierEnabled() {
            console.log(this.pointProgress);
            return this.topTier.threshold > this.pointProgress;
        },

        qtyCharts() {
            let qty = 0;

            if (this.nextRewardEnabled) {
                qty++;
            }
            if (this.nextTierEnabled) {
                qty++;
            }
            if (this.topTierEnabled) {
                qty++;
            }
            return qty;
        },
    },
    methods: {
        fetchTransactions() {
            this.$store.dispatch('loadTransactions', 10);
        },
    },
    filters: {
        date(value) {
            const date = new Date(value);

            return date.toLocaleDateString('en-US', {
                day: 'numeric',
                month: 'numeric',
                year: 'numeric',
            });
        },
    },
};
</script>

<style module lang="scss">
$pieSize: 200px;
$pieBorderWidth: 10px;

.header {
    display: flex;
    text-align: center;
    margin-top: 0.5em;

    & > * {
        flex-grow: 1;
    }
    .label {
        font-size: 0.9em;
    }
    .value {
        font-size: 1.5em;
        padding: 0 0.25em;
    }
}
.charts {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    margin-right: auto;

    @media (max-width: $pieSize * 2) {
        &.charts-2 {
            flex-direction: column;
        }
    }
    @media (max-width: $pieSize * 3) {
        &.charts-3 {
            flex-direction: column;
        }
    }
}
.chartContainer {
    height: $pieSize - 15px;
    width: $pieSize;
    position: relative;
    margin: 1em 0 0;
    /*border: solid 1px blue;*/
    text-align: center;
}
.pointsTo {
    margin-top: 50px;
    font-size: 2em;
    cursor: default;
}
.pointsToLabel {
    max-width: 80%;
    margin-left: auto;
    margin-right: auto;
    cursor: default;
}
.table {
    margin-top: 2em;
}
</style>
