<template>
    <FormLayout>
        <form method="post" @submit.prevent="submitForm">
            <BMessage type="is-danger" :active="!!error">
                <div v-html="error"></div>
            </BMessage>
            <BField
                label="Username or Email"
                :message="usernameErrors"
                :type="{ 'is-danger': usernameErrors.length > 0 }"
            >
                <BInput
                    v-model="username"
                    placeholder="Username"
                    name="username"
                    autocomplete="off"
                    :disabled="processing"
                    @blur="usernameBlurred = true"
                    @focus="usernameBlurred = false"
                />
            </BField>
            <BField label="Password" :message="passwordErrors" :type="{ 'is-danger': passwordErrors.length > 0 }">
                <BInput
                    type="password"
                    name="password"
                    v-model="password"
                    password-reveal
                    placeholder="Password"
                    :disabled="processing"
                    @blur="passwordBlurred = true"
                    @focus="passwordBlurred = false"
                />
            </BField>
            <BField>
                <BCheckbox v-model="rememberMe" :required="false" :disabled="processing">Remember Me</BCheckbox>
            </BField>
            <BButton type="is-primary" native-type="submit" :loading="processing" :disabled="!isValid" icon-left="login"
                >Log in</BButton
            >
            <div :class="$style.forgotPassword">
                <RouterLink :to="{ name: 'forgot_password' }">
                    Forgot Password
                </RouterLink>
            </div>
            <div v-if="showSocialLogin">
                <FacebookButton :disabled="processing" />
                <InstagramButton :disabled="processing" />
            </div>
        </form>
    </FormLayout>
</template>

<script>
import { axios } from '../lib/axios';
import { showSocial } from '../lib/config';
import { AUTH_ERROR, TARGET_PATH, USERNAME, TIER } from '../store/mutationTypes';
import { FIELD_REQUIRED, LOGIN_ACCOUNT_DISABLED } from '../lib/messages';
import FacebookButton from '../components/FacebookButton';
import InstagramButton from '../components/InstagramButton';
import FormLayout from '../layouts/FormLayout';

export default {
    components: { InstagramButton, FacebookButton, FormLayout },
    data() {
        return {
            username: null,
            password: null,
            rememberMe: false,
            processing: false,
            loginError: null,
            usernameBlurred: false,
            passwordBlurred: false,
            showSocialLogin: false
        };
    },
    computed: {
        error() {
            let error = '';
            if (this.loginError) {
                error = this.loginError;
            } else {
                error = this.$store.state.authError;
            }

            if (error === 'disabled') {
                error = LOGIN_ACCOUNT_DISABLED;
            }

            return error;
        },
        usernameErrors() {
            if (!this.username && this.usernameBlurred) {
                return [FIELD_REQUIRED];
            }

            return [];
        },
        passwordErrors() {
            if (!this.password && this.passwordBlurred) {
                return [FIELD_REQUIRED];
            }

            return [];
        },
        isValid() {
            if (this.usernameErrors.length > 0 || !this.username) {
                return false;
            }

            if (this.passwordErrors.length > 0 || !this.password) {
                return false;
            }

            return true;
        },
    },
    methods: {
        async submitForm() {
            if (!this.isValid) {
                return;
            }

            this.processing = true;
            this.loginError = null;
            // Since the user is trying to login with a username/pw, clear the previous auth error.
            this.$store.commit(AUTH_ERROR, null);

            let response;
            try {
                response = await axios.post('/login', {
                    username: this.username,
                    password: this.password,
                    remember_me: this.rememberMe,
                });
            } catch (e) {
                this.processing = false;
                this.loginError = e.response.data.error;
                this.password = null;
                this.passwordBlurred = false;
                return;
            }

            this.$store.commit(USERNAME, response.data.username);
            this.$store.commit(TIER, response.data.tier);
            this.$store.dispatch('initialize');

            this.processing = false;

            if (this.$store.state.targetPath !== null) {
                // forward to the requested url.
                this.$router.replace(this.$store.state.targetPath);
                // Clear the target path.
                this.$store.commit(TARGET_PATH, null);
            } else {
                // forward to the index
                this.$router.replace({ name: 'index' });
            }
        },
    },
};
</script>

<style module lang="scss">
.forgotPassword {
    padding-top: 1em;
    font-size: 0.95em;
}
</style>
