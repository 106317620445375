var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: _vm.$style.mainContentContainer }, [
    _c("div", { class: _vm.$style.header }, [
      _vm.logoPath
        ? _c("div", { class: _vm.$style.logoContainer }, [
            _c("img", {
              class: _vm.$style.logo,
              attrs: { src: _vm.logoPath, alt: _vm.storeName + " logo" }
            })
          ])
        : _vm._e()
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "content", class: _vm.$style.mainContent }, [
      _c("p", { staticClass: "userContent" }, [
        _vm._v(
          "This mobile application has been deprecated, but your points are still available. Please direct any questions about the loyalty program to the merchant."
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }