var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("FormLayout", [
    _c(
      "form",
      {
        attrs: { method: "post" },
        on: {
          submit: function($event) {
            $event.preventDefault()
            return _vm.submitForm.apply(null, arguments)
          }
        }
      },
      [
        _c("BMessage", { attrs: { type: "is-danger", active: !!_vm.error } }, [
          _c("div", { domProps: { innerHTML: _vm._s(_vm.error) } })
        ]),
        _vm._v(" "),
        _c(
          "BField",
          {
            attrs: {
              label: "Username or Email",
              message: _vm.usernameErrors,
              type: { "is-danger": _vm.usernameErrors.length > 0 }
            }
          },
          [
            _c("BInput", {
              attrs: {
                placeholder: "Username",
                name: "username",
                autocomplete: "off",
                disabled: _vm.processing
              },
              on: {
                blur: function($event) {
                  _vm.usernameBlurred = true
                },
                focus: function($event) {
                  _vm.usernameBlurred = false
                }
              },
              model: {
                value: _vm.username,
                callback: function($$v) {
                  _vm.username = $$v
                },
                expression: "username"
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "BField",
          {
            attrs: {
              label: "Password",
              message: _vm.passwordErrors,
              type: { "is-danger": _vm.passwordErrors.length > 0 }
            }
          },
          [
            _c("BInput", {
              attrs: {
                type: "password",
                name: "password",
                "password-reveal": "",
                placeholder: "Password",
                disabled: _vm.processing
              },
              on: {
                blur: function($event) {
                  _vm.passwordBlurred = true
                },
                focus: function($event) {
                  _vm.passwordBlurred = false
                }
              },
              model: {
                value: _vm.password,
                callback: function($$v) {
                  _vm.password = $$v
                },
                expression: "password"
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "BField",
          [
            _c(
              "BCheckbox",
              {
                attrs: { required: false, disabled: _vm.processing },
                model: {
                  value: _vm.rememberMe,
                  callback: function($$v) {
                    _vm.rememberMe = $$v
                  },
                  expression: "rememberMe"
                }
              },
              [_vm._v("Remember Me")]
            )
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "BButton",
          {
            attrs: {
              type: "is-primary",
              "native-type": "submit",
              loading: _vm.processing,
              disabled: !_vm.isValid,
              "icon-left": "login"
            }
          },
          [_vm._v("Log in")]
        ),
        _vm._v(" "),
        _c(
          "div",
          { class: _vm.$style.forgotPassword },
          [
            _c("RouterLink", { attrs: { to: { name: "forgot_password" } } }, [
              _vm._v("\n                Forgot Password\n            ")
            ])
          ],
          1
        ),
        _vm._v(" "),
        _vm.showSocialLogin
          ? _c(
              "div",
              [
                _c("FacebookButton", { attrs: { disabled: _vm.processing } }),
                _vm._v(" "),
                _c("InstagramButton", { attrs: { disabled: _vm.processing } })
              ],
              1
            )
          : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }