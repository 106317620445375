<template>
    <div class="is-hidden">
        <a :href="url" class="button" :class="$style.button" :disabled="disabled">
            <FaIcon :icon="['fab', 'instagram']" />
            &nbsp;
            <span><slot>Log in with Instagram</slot></span>
        </a>
    </div>
</template>

<script>
import { login } from '../lib/config';

export default {
    props: {
        disabled: {
            type: Boolean,
            default: false,
            required: false,
        },
    },
    data() {
        return {
            url: login.instagram.url,
        };
    },
};
</script>

<style module lang="scss">
.button {
    margin-top: 1em;
    background-color: #e33e5c;
    color: white;

    &:active,
    &:focus {
        color: white;
    }

    &[disabled] {
        background-color: #e33e5c;
    }
}
</style>
