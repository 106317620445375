const config = window.rewardsConfig;
// Clean up the global namespace.
delete window.rewardsConfig;

export const storeName = config.storeName;
export const username = config.username;
export const lastName = config.lastName;
export const firstName = config.firstName;

export const appTitle = config.appTitle;
export const tier = config.tier;

export const logoPath = config.logoPath;
export const showSocial = config.showSocial;
export const accentColor = config.accentColor;

export const login = config.login;
// This will only be defined when there was an unsuccessful OAuth login and info was populated
// in the session.
export const signUp = config.signUp;
