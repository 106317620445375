<template>
    <LoadingIndicator v-if="!initialized" />
    <div v-else>
        <div v-for="(tier, k) in tiers" :key="k" :class="$style.row">
            <div
                class="userContent"
                :style="hidden(tier)"
                :class="[$style.tier, inactiveTiers.indexOf(tier.id) >= 0 ? $style.tierInactive : '']"
            >
                <div :class="$style.iconColumn" :style="{ color: tier.color }">
                    <FaIcon icon="star" :class="$style.icon" />
                    <div
                        v-if="k > 0"
                        :class="[$style.vLine, $style.vLineFirst]"
                        :style="{ 'background-color': colors[k - 1] }"
                    ></div>
                    <div :class="[$style.vLine, $style.vLineLast]" :style="{ 'background-color': tier.color }"></div>
                </div>
                <div :class="$style.detailsColumn">
                    <div :class="$style.tierTitle">{{ tier.name }}</div>
                    <div :class="$style.tierDesc">{{ tier.description }}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';

import LoadingIndicator from '../components/LoadingIndicator';

export default {
    components: { LoadingIndicator },
    computed: {
        ...mapState(['tiers', 'tier', 'initialized']),
        colors() {
            let colors = [];
            for (const k in this.tiers) {
                colors.push(this.tiers[k].color);
            }

            return colors;
        },
        inactiveTiers() {
            let inactive = [];

            // Since the tiers are sorted, we just iterate through them until we find the active tier.
            for (const k in this.tiers) {
                if (this.tiers[k].id === this.tier.id) {
                    // We hit the active tier
                    break;
                }

                inactive.push(this.tiers[k].id);
            }

            return inactive;
        },
    },
    methods: {
        hidden(tier) {
            if ((tier.threshold == null) && (tier.id != this.tier.id)){
                return "display: none";
            }
            return "";
        }
    }
};
</script>

<style module lang="scss">
@import '../../../css/rewards/variables';
.row {
    border-bottom: $grey-lighter solid 2px;
}
.tier {
    display: flex;
}
.tierInactive {
    color: $grey-light;
}
.iconColumn {
    font-size: 2.5rem;
    position: relative;
    flex: 0 0 56px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.icon {
    // So it is on top of the vertical line.
    z-index: 2;
}
.detailsColumn {
    flex-grow: 1;
    padding: 10px;
}
.vLine {
    position: absolute;
    top: 0;
    // So it covers the bottom border.
    bottom: -2px;
    width: 4px;
    background-color: $black;
    // So it is on top of the bottom border
    z-index: 1;
}
.vLineFirst {
    bottom: 50%;
}
.vLineLast {
    top: 50%;
}
.tierTitle {
    font-weight: bold;
    padding-top: 0.5em;
}
.tierDesc {
    padding: 0.5em 0;
    // So line breaks are respected.
    white-space: pre-wrap;
}
</style>
