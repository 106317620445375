<template>
    <FormLayout>
        <h1>Forgot Password</h1>
        <transition name="slide-next">
            <div v-if="complete">
                <BMessage type="is-success">
                    Please check your email for a message with password reset instructions.
                </BMessage>
                <RouterLink :to="{ name: 'login' }">Back to Login</RouterLink>
            </div>
            <form method="post" @submit.prevent="submitForm" v-else>
                <BField
                    label="Username or Email"
                    :message="{ 'Please supply a username or email address': error }"
                    :type="{ 'is-danger': error }"
                >
                    <BInput v-model="username" placeholder="username" name="username" :disabled="processing" />
                </BField>
                <BButton type="is-primary" native-type="submit" :loading="processing">Send Reset Email</BButton>

                <div :class="$style.backToLogin">
                    <RouterLink :to="{ name: 'login' }">Back to login</RouterLink>
                </div>
                <BMessage type="is-info" :class="$style.backToLogin">
                    You can only request a new password once every 2 hours.
                </BMessage>
            </form>
        </transition>
    </FormLayout>
</template>

<script>
import { axios } from '../lib/axios';

import FormLayout from '../layouts/FormLayout';

export default {
    components: { FormLayout },
    data() {
        return {
            username: null,
            processing: false,
            complete: false,
            error: false,
        };
    },
    methods: {
        async submitForm() {
            // TODO: implement form validation
            if (!this.username) {
                this.error = true;
                return;
            }

            this.processing = true;
            this.complete = false;
            this.error = false;

            try {
                await axios.post('/login/send_token', {
                    username: this.username,
                });
            } catch (e) {
                this.processing = false;
                this.error = true;
                return;
            }

            this.complete = true;
            this.processing = false;
        },
    },
};
</script>

<style module>
.backToLogin {
    margin-top: 1em;
}
</style>
