<template>
    <FormLayout>
        <template v-slot:header>
            <h1>{{ appTitle }} Sign Up</h1>
        </template>
        <transition name="slide-prev">
            <div v-if="success === false">
                <BMessage type="is-danger" v-if="error === 'user_exists'">
                    This email address is already in use. <br />
                    Please <RouterLink :to="{ name: 'login' }">Log in</RouterLink> with your existing account.
                </BMessage>
                <BMessage type="is-danger" v-if="error === 'unknown'">
                    We're sorry, something went wrong. Please try again.
                </BMessage>
                <form method="post" @submit.prevent="submitForm">
                    <BField
                        label="First Name"
                        :message="firstNameErrors"
                        :type="{ 'is-danger': firstNameErrors.length > 0 }"
                    >
                        <BInput
                            v-model="firstName"
                            placeholder="First Name"
                            :disabled="processing"
                            @blur="_blur('firstName')"
                            @focus="_focus('firstName')"
                        />
                    </BField>
                    <BField
                        label="Last Name"
                        :message="lastNameErrors"
                        :type="{ 'is-danger': lastNameErrors.length > 0 }"
                    >
                        <BInput
                            v-model="lastName"
                            placeholder="Last Name"
                            :disabled="processing"
                            @blur="_blur('lastName')"
                            @focus="_focus('lastName')"
                        />
                    </BField>
                    <BField label="Email" :message="emailErrors" :type="{ 'is-danger': emailErrors.length > 0 }">
                        <BInput
                            v-model="email"
                            placeholder="Email"
                            :disabled="processing"
                            @blur="_blur('email')"
                            @focus="_focus('email')"
                        />
                    </BField>
                    <SetPasswordFields
                        :password1.sync="password"
                        :disabled="processing"
                        @isValid="passwordValid = $event"
                        single-field
                    />
                    <BButton type="is-primary" native-type="submit" :loading="processing" :disabled="!isValid">
                        Sign Up
                    </BButton>
                </form>
            </div>
            <BMessage v-if="success === true" type="is-success">
                {{ successMessage }}
            </BMessage>
        </transition>
    </FormLayout>
</template>

<script>
import { axios } from '../lib/axios';
import FormLayout from '../layouts/FormLayout';
import SetPasswordFields from '../components/SetPasswordFields';

import { FIELD_INVALID_EMAIL, FIELD_REQUIRED } from '../lib/messages';
import EmailValidator from '../lib/emailValidator';
import { appTitle, signUp } from '../lib/config';

export default {
    components: { FormLayout, SetPasswordFields },
    data() {
        return {
            firstName: '',
            lastName: '',
            email: '',
            password: '',

            error: '',
            success: false,
            successMessage: '',
            processing: false,
            submitAttempted: false,
            passwordValid: false,

            blurred: {
                firstName: false,
                lastName: false,
                email: false,
            },
            appTitle,
        };
    },
    computed: {
        firstNameErrors() {
            if (!this.blurred.firstName) {
                return [];
            }

            if (this.firstName.length === 0) {
                return [FIELD_REQUIRED];
            }

            return [];
        },
        lastNameErrors() {
            if (!this.blurred.lastName) {
                return [];
            }

            if (this.lastName.length === 0) {
                return [FIELD_REQUIRED];
            }
            return [];
        },
        emailErrors() {
            if (!this.blurred.email) {
                return [];
            }

            if (this.email.length === 0) {
                return [FIELD_REQUIRED];
            }

            if (!EmailValidator.test(this.email)) {
                return [FIELD_INVALID_EMAIL];
            }

            return [];
        },
        isValid() {
            return (
                this.passwordValid &&
                this.firstNameErrors.length === 0 &&
                this.lastNameErrors.length === 0 &&
                this.emailErrors.length === 0
            );
        },
    },
    mounted() {
        if (signUp !== undefined) {
            if (signUp.firstName) {
                this.firstName = signUp.firstName;
            }
            if (signUp.lastName) {
                this.lastName = signUp.lastName;
            }
            if (signUp.email) {
                this.email = signUp.email;
            }
        }
    },
    methods: {
        _blur(fieldName) {
            this.blurred[fieldName] = true;
        },
        _focus(fieldname) {
            this.blurred[fieldname] = false;
        },
        _validate() {
            this._blur('firstName');
            this._blur('lastName');
            this._blur('email');
        },
        async submitForm() {
            this._validate();

            if (!this.isValid) {
                return;
            }
            this.processing = true;

            let response;
            try {
                response = await axios.post('/sign_up', {
                    firstName: this.firstName,
                    lastName: this.lastName,
                    email: this.email,
                    password: this.password,
                });
            } catch (e) {
                this.processing = false;
                if (e.response.data && e.response.data.error) {
                    this.error = e.response.data.error;
                } else {
                    this.error = 'unknown';
                }
                return;
            }

            this.processing = false;

            if (response.data.success === true) {
                // notify the user of whatever the next step is
                this.error = null;
                this.success = true;
                this.successMessage = response.data.message;
            } else {
                this.error = response.data.error;
            }
        },
    },
};
</script>
