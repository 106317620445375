var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.error === "invalid"
        ? _c("BMessage", { attrs: { type: "is-danger" } }, [
            _vm._v(
              "\n        We're sorry, something went wrong. Please try again.\n    "
            )
          ])
        : _vm.error === "not_found"
        ? _c(
            "BMessage",
            { attrs: { type: "is-danger" } },
            [
              _vm._v(
                "\n        This reset link is either invalid or expired.\n        "
              ),
              _c("RouterLink", { attrs: { to: { name: "forgot_password" } } }, [
                _vm._v("Please request a new password reset.")
              ])
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "form",
        {
          on: {
            submit: function($event) {
              $event.preventDefault()
              return _vm.submitForm.apply(null, arguments)
            }
          }
        },
        [
          _c("SetPasswordFields", {
            attrs: {
              password1: _vm.password1,
              password2: _vm.password2,
              disabled: _vm.processing
            },
            on: {
              "update:password1": function($event) {
                _vm.password1 = $event
              },
              "update:password2": function($event) {
                _vm.password2 = $event
              },
              isValid: function($event) {
                _vm.isValid = $event
              }
            }
          }),
          _vm._v(" "),
          _c(
            "BButton",
            {
              class: _vm.$style.submit,
              attrs: {
                type: "is-primary",
                "native-type": "submit",
                loading: _vm.processing,
                disabled: !_vm.isValid
              }
            },
            [_vm._v("Set Password")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }