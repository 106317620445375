export const USERNAME = 'USERNAME';
export const TIER = 'TIER';
export const TARGET_PATH = 'TARGET_PATH';
export const AUTH_ERROR = 'AUTH_ERROR';

export const APP_LOADING = 'APP_LOADING';
export const APP_INITIALIZED = 'APP_INITIALIZED';
export const REWARDS = 'REWARDS';
export const POINTS = 'POINTS';
export const POINT_PROGRESS = 'POINT_PROGRESS';
export const OFFERS_ACTIVE = 'OFFERS_ACTIVE';
export const OFFERS_UPCOMING = 'OFFERS_UPCOMING';
export const TIERS = 'TIERS';
export const PROFILE = 'PROFILE';

export const TRANSACTIONS = 'TRANSACTIONS';
export const TRANSACTIONS_APPEND = 'TRANSACTIONS_APPEND';
export const TRANSACTIONS_LOADING = 'TRANSACTIONS_LOADING';
export const TRANSACTIONS_INCREMENT_PAGE_ID = 'TRANSACTIONS_INCREMENT_PAGE_ID';
export const TRANSACTIONS_END = 'TRANSACTIONS_END';
