var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "a",
      {
        staticClass: "button",
        class: _vm.$style.button,
        attrs: { href: _vm.url, disabled: _vm.disabled }
      },
      [
        _c("FaIcon", { attrs: { icon: ["fab", "facebook"] } }),
        _vm._v("\n         \n        "),
        _c(
          "span",
          [
            _vm._t("default", function() {
              return [_vm._v("Log in with Facebook")]
            })
          ],
          2
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }