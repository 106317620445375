var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    [
      _c(
        "BField",
        {
          attrs: {
            label: _vm.password1Label,
            message: _vm.password1Errors.concat(_vm.password1Warnings),
            type: {
              "is-danger":
                _vm.password1Errors.length > 0 ||
                _vm.password1Warnings.length > 0
            }
          }
        },
        [
          _c("BInput", {
            attrs: {
              type: "password",
              autocomplete: "new-password",
              "password-reveal": "",
              disabled: _vm.disabled,
              placeholder: "Password"
            },
            on: {
              blur: function($event) {
                _vm.password1Blurred = true
              },
              focus: function($event) {
                _vm.password1Blurred = false
              }
            },
            model: {
              value: _vm.password1Value,
              callback: function($$v) {
                _vm.password1Value = $$v
              },
              expression: "password1Value"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "BField",
        [
          _c("PasswordStrength", {
            staticClass: "control",
            attrs: {
              autocomplete: "new-password",
              "strength-meter-only": "",
              "secure-length": 8,
              disabled: _vm.disabled
            },
            on: { feedback: _vm._passwordFeedback },
            model: {
              value: _vm.password1Value,
              callback: function($$v) {
                _vm.password1Value = $$v
              },
              expression: "password1Value"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      !_vm.singleField
        ? _c(
            "BField",
            {
              attrs: {
                label: _vm.password2Label,
                message: _vm.password2Errors,
                type: { "is-danger": _vm.password2Errors.length > 0 }
              }
            },
            [
              _c("BInput", {
                attrs: {
                  type: "password",
                  "password-reveal": "",
                  disabled: _vm.disabled,
                  placeholder: "Repeat Password"
                },
                on: {
                  blur: function($event) {
                    _vm.password2Blurred = true
                  },
                  focus: function($event) {
                    _vm.password2Blurred = false
                  }
                },
                model: {
                  value: _vm.password2Value,
                  callback: function($$v) {
                    _vm.password2Value = $$v
                  },
                  expression: "password2Value"
                }
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }