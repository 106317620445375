<template>
    <NavBar
        :links="[
            { route: 'rewards', label: 'Rewards' },
            { route: 'points', label: 'Points' },
        ]"
    />
</template>

<script>
import NavBar from '../components/NavBar';

export default {
    components: { NavBar },
};
</script>
