var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    [
      _c(
        "BField",
        {
          attrs: {
            label: "First Name",
            message: [].concat(_vm.firstNameErrors),
            type: { "is-danger": _vm.firstNameErrors.length > 0 }
          }
        },
        [
          _c("BInput", {
            attrs: {
              type: "text",
              disabled: _vm.disabled,
              autocomplete: "given-name",
              placeholder: "First Name"
            },
            on: {
              blur: function($event) {
                _vm.firstNameBlurred = true
              },
              focus: function($event) {
                _vm.firstNameBlurred = false
              }
            },
            model: {
              value: _vm.firstNameValue,
              callback: function($$v) {
                _vm.firstNameValue = $$v
              },
              expression: "firstNameValue"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "BField",
        {
          attrs: {
            label: "Last Name",
            message: _vm.lastNameErrors,
            type: { "is-danger": _vm.lastNameErrors.length > 0 }
          }
        },
        [
          _c("BInput", {
            attrs: {
              type: "text",
              disabled: _vm.disabled,
              autocomplete: "family-name",
              placeholder: "Last Name"
            },
            on: {
              blur: function($event) {
                _vm.lastNameBlurred = true
              },
              focus: function($event) {
                _vm.lastNameBlurred = false
              }
            },
            model: {
              value: _vm.lastNameValue,
              callback: function($$v) {
                _vm.lastNameValue = $$v
              },
              expression: "lastNameValue"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }