var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("nav", { staticClass: "tabs is-fullwidth" }, [
      _c(
        "ul",
        _vm._l(_vm.links, function(l) {
          return _c(
            "li",
            { class: { "is-active": _vm.$route.name === l.route } },
            [
              _c(
                "RouterLink",
                {
                  style: _vm.linkStyle(l.route),
                  attrs: { to: { name: l.route } }
                },
                [_vm._v(_vm._s(l.label))]
              )
            ],
            1
          )
        }),
        0
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }