var render = function(_h, _vm) {
  var _c = _vm._c
  return _c("div", { class: _vm.$style.formLayout }, [
    _c("div", { class: _vm.$style.header }, [_vm._t("header")], 2),
    _vm._v(" "),
    _c("div", { staticClass: "userContent" }, [_vm._t("default")], 2)
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }