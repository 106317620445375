import Vue from 'vue';
import Vuex from 'vuex';

import { username, login, tier } from '../lib/config';
import {
    AUTH_ERROR,
    REWARDS,
    APP_LOADING,
    TARGET_PATH,
    TIER,
    USERNAME,
    POINTS,
    POINT_PROGRESS,
    APP_INITIALIZED,
    TIERS,
    TRANSACTIONS,
    TRANSACTIONS_END,
    TRANSACTIONS_LOADING,
    TRANSACTIONS_INCREMENT_PAGE_ID,
    TRANSACTIONS_APPEND,
    PROFILE,
} from './mutationTypes';

import { axios } from '../lib/axios';

Vue.use(Vuex);

let stateTimeoutId = null;
function reload(timeout) {
    if (stateTimeoutId) {
        clearInterval(stateTimeoutId);
    }

    stateTimeoutId = setInterval(() => {
        store.dispatch('initialize');
    }, timeout);
}

export const store = new Vuex.Store({
    state: {
        username: username,
        tier: tier,
        authError: login.authError,
        targetPath: null,

        appLoading: true,
        initialized: false,
        points: 0,
        pointProgress: 0,
        rewards: [],
        tiers: [],
        profile: [],

        transactions: [],
        transactionsPageId: -1,
        transactionsLoading: false,
        transactionsEnd: false,
    },
    mutations: {
        [USERNAME](state, payload) {
            state.username = payload;
        },
        [TIER](state, payload) {
            state.tier = payload;
        },
        [TARGET_PATH](state, payload) {
            state.targetPath = payload;
        },
        [AUTH_ERROR](state, payload) {
            state.authError = payload;
        },
        [APP_LOADING](state, payload) {
            state.appLoading = payload;
        },
        [APP_INITIALIZED](state, payload) {
            state.initialized = payload;
        },
        [POINTS](state, payload) {
            state.points = payload;
        },
        [POINT_PROGRESS](state, payload) {
            state.pointProgress = payload;
        },
        [TIERS](state, payload) {
            state.tiers = payload;
        },
        [REWARDS](state, payload) {
            state.rewards = payload;
        },
        [PROFILE](state, payload) {
            state.profile = payload;
        },
        [TRANSACTIONS](state, payload) {
            state.transactions = payload;
        },
        [TRANSACTIONS_APPEND](state, payload) {
            for (let i = 0; i < payload.length; i++) {
                state.transactions.push(payload[i]);
            }
        },
        [TRANSACTIONS_END](state, payload) {
            state.transactionsEnd = payload;
        },
        [TRANSACTIONS_INCREMENT_PAGE_ID](state) {
            state.transactionsPageId++;
        },
        [TRANSACTIONS_LOADING](state, payload) {
            state.transactionsLoading = payload;
        },
    },
    getters: {
        isLoggedIn: state => {
            return state.username !== null;
        },
    },
    actions: {
        async initialize({ commit, dispatch, state }) {
            let data;
            try {
                const response = await axios.get('/state');
                data = response.data;
            } catch (e) {
                // try again in 10 seconds
                reload(10 * 1000);
                return;
            }

            commit(REWARDS, data.rewards);
            commit(TIERS, data.tiers);
            commit(POINTS, data.points);
            commit(POINT_PROGRESS, data.pointProgress);
            commit(PROFILE, data.profile);

            commit(APP_INITIALIZED, true);

            if (state.transactions.length > 0) {
                dispatch('loadTransactions', {
                    refreshOnly: true,
                });
            }
            // Refresh in 30 minutes
            reload(30 * 60 * 1000);
        },
        async loadTransactions({ commit, state }, options) {
            let pageSize = options,
                pageId,
                refreshOnly = false;
            if (options.refreshOnly !== undefined && options.refreshOnly === true) {
                // Always load at least 10. If they only had a few, and earned some
                // points since last transaction, we want to show all of them.
                pageSize = Math.max(state.transactions.length, 10);
                pageId = 0;
                refreshOnly = true;
            } else {
                commit(TRANSACTIONS_LOADING, true);
                commit(TRANSACTIONS_INCREMENT_PAGE_ID);
                pageId = state.transactionsPageId;
            }

            const { data } = await axios.get('transactions', {
                params: {
                    page: pageId,
                    qty: pageSize,
                },
            });

            if (data.rows.length < pageSize) {
                commit(TRANSACTIONS_END, true);
            }

            if (refreshOnly) {
                commit(TRANSACTIONS, data.rows);
            } else {
                commit(TRANSACTIONS_APPEND, data.rows);
                commit(TRANSACTIONS_LOADING, false);
            }
        },
    },
});

if (username) {
    // we're logged in so lets initialize.
    store.dispatch('initialize');
}
